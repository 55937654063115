import { SUPABASE_KEYS } from '@/config/supabase-keys';
import { CompanyMembers, User } from '@/types';
import { SendInvite, UpdateInvitedUser } from '@/types';
import { supabase } from '@/utils/supabase/client';
import { UserResponse } from '@supabase/supabase-js';

export interface UserResponseType {
  data: User[];
  pageCount: number;
}

const PAGE_SIZE = 5;
const getProfile = async (id: string, searchQuery?: string, pageIndex?: number): Promise<UserResponseType> => {
  const range = pageIndex ? pageIndex - 1 : 0;
  const offset = range * PAGE_SIZE;
  let query = supabase.from(SUPABASE_KEYS.USERS).select('*', { count: 'exact' }).neq('id', id);

  if (offset) {
    query = query.range(offset, offset + PAGE_SIZE + 1);
  }
  if (searchQuery) {
    //#TODO: CAN'T SEARCH MEMBERS BY ROLE BECAUSE ROLE IS TYPE ROLE AND IT IS NOT SEARCHABLE BY ILIKE
    query = query.or(`name.ilike.%${searchQuery}%,firstname.ilike.%${searchQuery}%,lastname.ilike.%${searchQuery}%`);
  }
  const { data, count, error } = await query.limit(5);

  const PAGE_COUNT = Math.ceil(count / PAGE_SIZE);

  if (error) {
    throw new Error(error.message);
  }

  return {
    data: data ?? [],
    pageCount: PAGE_COUNT,
  };
};

const getInvitedUser = async (): Promise<UserResponse> => {
  const data = await supabase.auth.getUser();

  return data;
};

const getSession = async () => {
  const { data: session } = await supabase.auth.getSession();

  return session;
};

const getUserId = async (): Promise<string> => {
  const {
    data: {
      user: { id },
    },
  } = await supabase.auth.getUser();

  return id;
};
const getLoggedUser = async (id: string) => {
  const { data } = await supabase.from(SUPABASE_KEYS.USERS).select('*').eq('id', id).single();
  return data;
};

const getInvitedMember = async (id: string): Promise<User> => {
  const { data, error } = await supabase.from(SUPABASE_KEYS.USERS).select().eq('id', id).single();
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

const getCompanyMembers = async (id: string, searchQuery?: string): Promise<CompanyMembers[]> => {
  let query = supabase.from(SUPABASE_KEYS.USERS).select('*').eq('company', id);

  if (searchQuery) {
    query = query.or(`name.ilike.%${searchQuery}%,role.ilike.%${searchQuery}%`);
  }

  const { data, error } = await query;
  if (error) {
    throw new Error(error.message);
  }
  return data;
};
const setMemberRole = async (role: string, id: string): Promise<any> => {
  const { data, error } = await supabase.from(SUPABASE_KEYS.USERS).upsert({ role: role }).eq('id', id);

  if (error) {
    throw new Error(error.message);
  }
  return data;
};
const updateInvitedUser = async ({ id, company, firstname, lastname }: UpdateInvitedUser): Promise<void> => {
  const { error } = await supabase
    .from(SUPABASE_KEYS.USERS)
    .update({ name: `${firstname} ${lastname}`, company, firstname, lastname })
    .eq('id', id);
  if (error) {
    throw new Error(error.message);
  }
};

const sendInvite = async ({ email, firstname, lastname, company, role, redirect }: SendInvite): Promise<void> => {
  await fetch('/api/auth/invite', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, firstname, lastname, company, role, redirect }),
  });
};

const setPassword = async (password: string, id: string, company: string): Promise<void> => {
  await fetch('/api/auth/set-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, password, company }),
  });
};

const deleteMember = async (id: string): Promise<void> => {
  await fetch('/api/auth/delete-member', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  });
};

export {
  getProfile,
  deleteMember,
  getCompanyMembers,
  sendInvite,
  setMemberRole,
  setPassword,
  getInvitedMember,
  getInvitedUser,
  updateInvitedUser,
  getUserId,
  getLoggedUser,
  getSession,
};
